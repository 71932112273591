import styles from './FaqItem.module.scss';
import { FC, useEffect, useRef, useState } from 'react';
import ChervonIcon from '#components/ui/Icons/Chevron';

type Props = {
  question: string;
  answer: string;
  index: number;
  isActive: boolean;
  setActiveElem: React.Dispatch<React.SetStateAction<number | null>>;
};

const FaqItem: FC<Props> = ({ question, answer, index, isActive, setActiveElem }) => {
  const content = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const { scrollHeight } = content.current as HTMLDivElement;
    setHeight(isActive ? scrollHeight : 0);
  }, [isActive]);

  const toggleAccordion = () => {
    setActiveElem(isActive ? null : index);
  };

  const handleActive = (className: string) => {
    return `${className} ${isActive ? styles.active : ''}`;
  };

  return (
    <div className={styles.wrapper}>
      <div className={handleActive(styles.item)} onClick={toggleAccordion}>
        <h3 className={handleActive(styles.title)}>{question}</h3>
        <span className={handleActive(styles.icon)}>
          <ChervonIcon />
        </span>
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${height}px` }}
        className={styles.content}
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
      >
        <p
          className={styles.text}
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
        >
          {answer}
        </p>
      </div>
    </div>
  );
};

export default FaqItem;
